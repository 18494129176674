/* src/css/About.css */
.about-container {
    padding: 40px 20px;
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
  }
  
  .about-container h2 {
    font-size: 2.5em;
    color: white;
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 1.2em;
    color: #333333;
    max-width: 800px;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .about-container .about-content{
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }