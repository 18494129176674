/* src/css/Footer.css */
.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.footer li {
  margin: 0 15px;
}

.footer a {
  text-decoration: none;
  color: #333;
  font-size: 1em;
}

.footer p {
  margin: 0;
  font-size: 0.9em;
  color: #999;
}
