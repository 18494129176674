/* src/components/Header.css */
.header {
  padding: 20px;
  /* background-color: #f5f5f5; */
  z-index: 1;
}

.header h1 {
  font-size: 2.5em;
  margin: 0;
  color: white;
}

.header h2 {
  font-size: 1.5em;
  color:#deecfb;
}

@media screen and (max-width: 768px) {
  .header h1 {
    line-height: 1.1;
  }
  .header h2{
    line-height: 1.1;
    margin: 10px;
  }
  
}