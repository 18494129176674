/* src/css/SearchResultFlight.css */
.flight-result {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 50%;
}

.flight-result:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.flight-result p {
  margin: 8px 0;
  font-size: 1em;
  color: #333;
}

.flight-result p:first-of-type {
  font-weight: bold;
  color: #368ce7;
}

@media screen and (max-width: 768px) {
  .flight-result {
    width: 100%;
  }
}