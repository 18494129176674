.hotel-result {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.hotel-result:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.hotel-result p {
  margin: 8px 0;
  font-size: 1.2em;
  color: #333;
  text-align: left;
}

.hotel-result p.hotel-description {
  font-weight: bold;
  color: #368ce7;
  margin-bottom: 15px;
  text-align: center;
}

.hotel-name-rating {
  text-align: center;
  margin-bottom: 15px;
}

.hotel-name {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.stars {
  display: block;
  text-align: left;
  margin-bottom: 15px;
}

.hotel-detail {
  font-weight: 500;
  color: #333;
}

.hotel-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 10px;
}

.hotel-actions {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.hotel-booking-link, .hotel-share-link {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
  cursor: pointer;
  font-size: 1em;
}

.hotel-booking-link {
  background-color: #007BFF;
  color: white;
}

.hotel-booking-link:hover {
  background-color: #0056b3;
}

.hotel-share-link {
  background-color: white;
  color: #007BFF;
  border: none;
}

.hotel-share-link:hover {
  background-color: #007BFF;
  color: white;
}

p.copy-success {
  color: #5ce65c;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .hotel-result {
    width: 100%;
  }
  
  .hotel-details-grid {
    grid-template-columns: 1fr;
  }

  .hotel-actions {
    flex-direction: column;
  }

  .hotel-booking-link, .hotel-share-link {
    margin-bottom: 10px;
  }
}
