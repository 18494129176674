@keyframes move-left-to-right {
  0% {
    left: var(--start-left, -200px);
  }
  100% {
    left: 100%;
  }
}

@keyframes move-right-to-left {
  0% {
    left: var(--start-right, 100%);
  }
  100% {
    left: -200px;
  }
}

.cloud-animation {
  position: absolute;
  /* opacity: 0.7; */
}

.cloud-animation.left-to-right {
  animation: move-left-to-right var(--duration, 60s) linear infinite;
}

.cloud-animation.right-to-left {
  animation: move-right-to-left var(--duration, 60s) linear infinite;
}

@media screen and (max-width: 768px) {
  .cloud-animation {
    opacity: 0.5;
  }
}

/* Plane animation */
@keyframes move-plane-left-to-right {
  0% {
    left: -300px;
  }
  100% {
    left: 130%;
  }
}

.airplane-animation {
  position: absolute;
  animation: move-plane-left-to-right 15s linear forwards; /* Adjust duration as needed */
  animation-delay: 50s; /* 50 seconds delay */
  animation-iteration-count: 1; /* Run only once */
  visibility: hidden; /* Initially hidden */
}

.airplane-animation.start-animation {
  visibility: visible; /* Show when animation starts */
}

/* UFO animation */
@keyframes move-ufo-left-to-right {
  0% {
    left: -300px;
  }
  100% {
    left: 130%;
  }
}

.ufo-animation {
  position: absolute;
  animation: move-ufo-left-to-right 15s linear forwards; /* Adjust duration as needed */
  animation-delay: 50s; /* 50 seconds delay */
  animation-iteration-count: 1; /* Run only once */
  visibility: hidden; /* Initially hidden */
}

.ufo-animation.start-animation {
  visibility: visible; /* Show when animation starts */
}
