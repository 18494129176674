.search-results {
    z-index: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
  }
  
  .search-results h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #deecfb;
  }
  
  @media screen and (max-width: 768px) {
    .search-results {
      width: 90%;
    }
    
  }