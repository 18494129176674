/* src/css/NavBar.css */
.nav-bar {
  background-color: #fff;
}

.nav-bar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo a{
  text-decoration: none;
}

.logo img{
height:80px;
}

.nav-bar .logo h1 {
  font-size: 1.8em;
  font-weight: bold;
  color: #368ce7;
}

.nav-bar .nav-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.nav-bar .nav-links ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-bar .nav-links li {
  margin-left: 30px;
}

.nav-bar .nav-links a {
  color: #333;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 10px;
}

.nav-bar .nav-links a:hover {
  color: white;
  background-color: #368ce7;
  background-image: linear-gradient(315deg, #368ce7 0%, #5899e2 74%);
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

.hamburger.open .line.top {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .line.middle {
  opacity: 0;
}

.hamburger.open .line.bottom {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .nav-bar .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 70px; /* Adjust based on your nav-bar height */
    left: 0;
    border-top: 1px solid #ccc;
    z-index: 3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #e0e0e0;
  }

  .nav-bar .nav-links.open {
    display: flex;
    max-height: 500px; /* Adjust to a value that fits your menu */
    opacity: 1;
  }

  .nav-bar .nav-links ul {
    flex-direction: column;
    width: 100%;
  }

  .nav-bar .nav-links li {
    margin: 10px 0;
  }

  .hamburger {
    display: flex;
    justify-content: space-evenly;
  }
}
