/* src/css/BlogPost.css */
.blog-post-container {
  padding: 40px 100px;
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
  background: white;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
}

.blog-post-container h2 {
  font-size: 2.5em;
  color: #368ce7;
  margin-bottom: 20px;
}

.blog-post-container .date {
  font-size: 0.9em;
  color: #666666;
  margin-bottom: 10px;
}

.blog-post-container p {
  font-size: 1.2em;
  color: #666666;
  line-height: 1.6;
}

.blog-post-container ul {
  text-align: left;
  padding-left: 40px;
}

.blog-post-container li {
  margin-bottom: 10px;
}

.share-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share-button, .back-button {
  font-size: 1em;
  color: #368ce7;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid #368ce7;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: white;
  cursor: pointer;
}

.share-button:hover, .back-button:hover {
  background-color: #368ce7;
  color: #ffffff;
}

.copied-message {
  margin-top: 10px;
  font-size: 0.9em;
  color: green;
}

.blog-post-container .bottom-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  align-self: start;
}

@media screen and (max-width: 768px) {
.blog-post-container{
  padding: 20px 30px;
}
}