/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Other styles */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  font-family: 'Poppins', Arial, sans-serif;
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%);
}

.main-content {
  flex: 1;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
}

.full-width {
  width: 100%;
}

button {
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  background-color: aliceblue;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: box-shadow 0.3s ease; /* Add transition for smoother effect */
  font-family: 'Poppins', Arial, sans-serif;
}

button:hover {
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
