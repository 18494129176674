/* src/css/FAQ.css */
.faq-container {
    padding: 40px 20px;
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
  }
  
  .faq-container h2 {
    font-size: 2.5em;
    color: white;
    margin-bottom: 20px;
  }
  
  .faq-container .faq-item {
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-container .faq-item h3 {
    font-size: 1.5em;
    color: #333333;
    margin: 0;
  }
  
  .faq-container .faq-item p {
    font-size: 1.2em;
    color: #666666;
    line-height: 1.6;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .faq-container .faq-item.open p {
    max-height: 1000px; /* Large value to ensure content is fully visible */
    padding-top: 10px;
  }
  