.share-buttons-social-media{
display: flex;
flex-direction: row;
gap: 0.5em;
justify-content: center;
align-items: center;
margin: 0 auto;
background: white;
border-radius: 10px;
width: fit-content;
padding: 5px 10px;
z-index: 2;
margin-top: 1em;
/* transparent glass */
border-radius: 12px;
background: rgba(255, 255, 255, 0.2);
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);
}

.share-buttons-social-media .general-share-button {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background: none;
    box-shadow: none;
    margin: 5px;
    padding: 0;
  }
  
  .share-buttons-social-media .icon-button {
    border: none;
    padding: 0;
    cursor: pointer;
    color:#368ce7;
    color: white;
    transition: transform 0.2s;
    height: 30px;
    width: 30px;
    background: unset;
    box-shadow:unset;
  }
  
  .share-buttons-social-media .icon-button:hover, .share-buttons-social-media .general-share-button .icon-button:hover {
    transform: scale(1.1);
    box-shadow: unset;
    color:#368ce7
  }
  
  .share-buttons-social-media  .icon-button:focus {
    outline: none;
  }
  
  .share-buttons-social-media  .copy-feedback {
    margin-left: 10px;
    color:#368ce7;
    background-color: white;
    font-size: 14px;
    border-radius: 10px;
    padding: 5px 10px;
  }