.search-bar {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  text-align: left;
  z-index: 2;
}

.search-buttons {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
  gap: 1em;
}

.search-buttons button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 1em;
  border-radius: 10px;
  font-family: 'Poppins', Arial, sans-serif;
  color: #999999;
  border: 1px solid #ccc;
  background-color: aliceblue;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: box-shadow 0.3s ease; /* Add transition for smoother effect */
  font-weight: 300;
}

.search-buttons button.active {
  background-color: white;
  color: #368ce7;
  font-weight: 500;
}

.search-buttons button:hover {
  color: #368ce7;
  background-color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance drop shadow on hover */
}

.search-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  position: relative;
}

.error-message {
  font-weight: 500;
  color: rgb(29, 29, 29);
  font-size: 0.9em;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-image: linear-gradient(to right, crimson, white);
  border-radius: 5px;
  width: fit-content;
}

.no-results-message{
  font-weight: 500;
  color: rgb(29, 29, 29);
  font-size: 0.9em;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-image: linear-gradient(to right, rgb(240, 211, 47), white);
  border-radius: 5px;
  width: fit-content;
}

textarea {
  width: 98%;
  padding: 10px;
  font-size: 1em;
  border-radius: 15px;
  min-height: 50px;
  font-family: 'Poppins', Arial, sans-serif; 
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.2);
  resize: none;
}

.search-icon-button {
  padding: 0;
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 1.5em;
  background-color: white;
  color: #368ce7;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* transform: rotate(-30deg); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  transition: box-shadow 0.3s ease; /* Add transition for smoother effect */
}

.search-icon-button svg{
  transform: rotate(-15deg);
}

.search-icon-button:hover {
  background-color: #368ce7;
  background-image: linear-gradient(315deg, #368ce7 0%, #5899e2 74%);
  color: white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance drop shadow on hover */
}

.text-counter{
position: relative;
width: 100%;
}

.character-count{
position: absolute;
right: 1%;
bottom: 20%;
font-size: small;
color: #999999;
opacity: 0.5;
}

.coming-soon-message{
font-weight: 500;
font-size: 5em;
text-align: center;
color: burlywood;
}

@media screen and (max-width: 768px) {
  textarea{
    min-height:100px ;
  }
  .character-count{
    bottom: 10%;
  }
  
}